import React from 'react'
import styled, { css } from 'styled-components'
import { media } from '../../styles/media'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { FlexCenter } from '../CssTools'


const StyledWrap = styled.div`
  width:100%;
  padding:0 16px;
  display:flex;
  flex-flow:row nowrap;
  align-items:flex-start;
  justify-content:space-between;
  p{
    font-size:12px;
    line-height:1.1em;
  }
  ${() => media.tablet(css`
    max-width:600px;
    margin:0 auto;
  `)}
`

const StyledPhoto1 = styled.div`
  width:48%;
  .gatsby-image-wrapper{
    background:${({ theme }) => theme.colors.primary.pink};
    height:100px;
  ${() => media.tablet(css`
    height:200px!important;
    width:100%;
  `)}
    img{
      height:95%;
      width:auto;
      margin:auto auto;
  ${() => media.tablet(css`
    height:70%!important;
  `)}
    }
  }
`
const StyledPhoto2 = styled.div`
  width:48%;
  .gatsby-image-wrapper{
    height:100px;
      ${() => media.tablet(css`
    height:200px!important;
  `)}
  }
`

const TwoPics = () => {
  const data = useStaticQuery(query)
  return (
    <StyledWrap>
      <StyledPhoto1>
        <StaticImage
          src='../../images/machine.png'
          placeholder='none'
          alt='分析機械'
        />
        <p>{data.text.bacterialFlora.imgtxt[0]}</p>
      </StyledPhoto1>
      <StyledPhoto2>
        <StaticImage
          src='../../images/laboImage.jpg'
          placeholder='none'
          alt='岩国研究所'
        />
        <p>{data.text.bacterialFlora.imgtxt[1]}</p>
      </StyledPhoto2>
    </StyledWrap>
  )
}

export const query = graphql`
  {
    text: pagesYaml(pageName: {eq: "laboPage"}) {
      bacterialFlora {
        imgtxt
      }
    }
  }
`

export default TwoPics
